<template>
  <div
    class="co-flex-col py-8 px-16"
  >
    <div class="co-flex-row co-justify-between co-items-end my-6">
      <span class="text-h5">{{ title }}</span>
      <span
        class="co-text-sm"
        @click="toLogin"
      >
        {{ tipLogin.hint }}
        <span
          class="primary--text"
          style="cursor: pointer;"
        >{{ tipLogin.text }}</span>
      </span>
    </div>
    <v-form
      v-if="state === states.reset"
      ref="form"
      lazy-validation
    >
      <v-text-field
        v-model="txtPhone.value"
        :label="txtPhone.label"
        outlined
        dense
        required
        :prepend-inner-icon="txtPhone.prependInner"
        :rules="phoneRules"
      />

      <v-text-field
        v-model="txtCode.value"
        :label="txtCode.label"
        outlined
        dense
        required
        :prepend-inner-icon="txtCode.prependInner"
        :rules="codeRules"
      >
        <template #append>
          <span
            class="pt-1"
            :class="[txtCode.color ? `${txtCode.color}--text` : 'blue-grey--text']"
            style="cursor: pointer;"
            @click="sendMsgCode"
          >{{ txtCode.appendInner }}</span>
        </template>
      </v-text-field>

      <v-text-field
        v-model="txtPassword1.value"
        :label="txtPassword1.label"
        outlined
        type="password"
        dense
        required
        :prepend-inner-icon="txtPassword1.prependInner"
        :rules="pwd1Rules"
      />
      <v-text-field
        v-model="txtPassword2.value"
        :label="txtPassword2.label"
        outlined
        type="password"
        dense
        required
        :prepend-inner-icon="txtPassword2.prependInner"
        :rules="pwd2Rules"
      />
      <v-btn
        depressed
        :loading="btnOk.loading"
        :color="btnOk.color"
        class="co-w-full mt-6"
        height="40"
        @click="onSubmit"
      >
        {{ btnOk.text }}
      </v-btn>
    </v-form>
    <div
      v-if="state === states.success"
      class="co-flex-col co-justify-center co-items-center"
    >
      <v-icon
        size="240"
        :color="tipSuccess.color"
      >
        {{ tipSuccess.icon }}
      </v-icon>
      <span
        class="text-h5"
        :class="[`${tipSuccess.color}--text`]"
      >
        {{ tipSuccess.text }}
      </span>
    </div>
    <div
      v-if="state === states.error"
      class="co-flex-col co-justify-center co-items-center"
    >
      <v-icon
        size="240"
        :color="tipError.color"
      >
        {{ tipError.icon }}
      </v-icon>
      <span
        class="text-h5"
        :class="[`${tipError.color}--text`]"
      >
        {{ tipError.text }}
      </span>
    </div>
  </div>
</template>

<script>
  import lib from '@/api/co.lib'
  export default {
    data () {
      return {
        title: '重置密码',
        tipLogin: {
          hint: '我已注册，直接',
          text: '登录'
        },
        tipSuccess: {
          icon: 'mdi-check-circle-outline',
          text: '密码重置成功',
          color: 'green',
          guide: '去登录'
        },
        tipError: {
          icon: 'mdi-alert-circle-outline',
          text: '密码重置失败',
          color: 'red',
          guide: '重新处理'
        },
        txtPhone: {
          label: '手机号码',
          prependInner: 'mdi-cellphone',
          appendInner: '',
          value: ''
        },
        txtPassword1: {
          label: '输入新密码',
          prependInner: 'mdi-lock-outline',
          value: ''
        },
        txtPassword2: {
          label: '确认新密码',
          prependInner: 'mdi-lock-outline',
          value: ''
        },
        txtCode: {
          label: '验证码',
          prependInner: 'mdi-shield-key-outline',
          appendInner: '获取验证码',
          value: '',
          timer: null,
          count: 60,
          disabled: false,
          color: 'black'
        },
        phoneRules: [
          val => !!val || '必须填写手机号码',
          val => /^[1][3-9][0-9]{9}$/.test(val) || '请输入正确的手机号码'
        ],
        codeRules: [
          val => !!val || '必须填写验证码',
          val => /^[0-9]{6}$/.test(val) || '请输入正确的验证码'
        ],
        pwd1Rules: [
          val => !!val || '必须输入新密码',
          val => val.length >= 6 || '新密码至少6位'
        ],
        pwd2Rules: [
          val => !!val || '必须输入确认密码',
          val => val.length >= 6 || '确认密码至少6位'
        ],
        states: {
          reset: 'reset',
          success: 'suceess',
          error: 'error'
        },
        state: 'reset',
        btnOk: {
          loading: false,
          color: 'primary',
          text: '确定'
        }
      }
    },
    methods: {
      clearCodeTimer () {
        if (this.txtCode.timer) {
          clearInterval(this.txtCode.timer)
          this.txtCode.timer = null
        }
      },
      startCodeTimer () {
        const me = this
        const decreaseCount = function () {
          me.txtCode.count -= 1
          if (me.txtCode.count < 1) {
            me.clearCodeTimer()
            me.txtCode.appendInner = '获取验证码'
            me.txtCode.disabled = false
            me.txtCode.color = 'black'
          } else {
            me.txtCode.appendInner = `(${me.txtCode.count}S)后重发`
          }
        }

        this.txtCode.count = 60
        this.txtCode.disabled = true
        this.txtCode.color = 'grey'
        this.txtCode.timer = setInterval(decreaseCount, 1000)
      },
      sendMsgCode () {
        const me = this
        const executing = function () {
          me.startCodeTimer()
        }

        const executed = function (res) {
          // console.log('sendMsgCode, res: %o', res)
          if (res.status) {
            me.$notify({
              title: '成功提示',
              message: '验证码发送成功！',
              type: 'success'
            })
          } else {
            me.$notify({
              title: '错误提示',
              message: '验证码发送失败！',
              type: 'error'
            })
          }
        }

        lib.sso.sendMsgCode({
          mobile: this.txtPhone.value,
          executing,
          executed
        })
      },
      toReset () {
        this.state = this.states.reset
      },
      toSubmit () {
        const me = this
        const executing = function () {
          me.btnOk.loading = true
        }

        const executed = function (res) {
          me.btnOk.loading = false
          if (res.status) {
            me.state = me.states.success
          } else {
            me.state = me.states.error
          }
        }

        lib.sso.forgetPassword({
          mobile: this.txtPhone.value,
          code: this.txtCode.value,
          newPassword: this.txtPassword2.value,
          executing,
          executed
        })
      },
      onSubmit () {
        if (!this.$refs.form.validate()) {
          return
        }

        const pwd1 = this.txtPassword1.value
        const pwd2 = this.txtPassword2.value
        if (pwd1 !== pwd2) {
          this.$notify({
            title: '错误提示',
            message: '新密码与确认密码不一致，重新重新输入密码！',
            type: 'error'
          })
          return
        }

        this.toSubmit()
      },
      toLogin () {
        this.$emit('login', {
          mode: 'phone'
        })
      }
    }
  }
</script>
